export const fruitData = {
    gapple: {
        base: "/images/socials/game/gapplew.webp",
        top: "/images/socials/game/gappleb.webp",
        bottom: "/images/socials/game/gapplet.webp",
        splat: "/images/socials/game/splat.webp",
    },
    rapple: {
        base: "/images/socials/game/rapplew.webp",
        top: "/images/socials/game/rappleb.webp",
        bottom: "/images/socials/game/rapplet.webp",
        splat: "/images/socials/game/splat.webp",
    },
    banana: {
        base: "/images/socials/game/bananaw.webp",
        top: "/images/socials/game/bananab.webp",
        bottom: "/images/socials/game/bananat.webp",
        splat: "/images/socials/game/splat.webp",
    },
    coconut: {
        base: "/images/socials/game/coconutw.webp",
        top: "/images/socials/game/coconutb.webp",
        bottom: "/images/socials/game/coconutt.webp",
        splat: "/images/socials/game/splat.webp",
    },
    honeydew: {
        base: "/images/socials/game/honeydeww.webp",
        top: "/images/socials/game/honeydewb.webp",
        bottom: "/images/socials/game/honeydewt.webp",
        splat: "/images/socials/game/splat.webp",
    },
    kiwi: {
        base: "/images/socials/game/kiwiw.webp",
        top: "/images/socials/game/kiwib.webp",
        bottom: "/images/socials/game/kiwit.webp",
        splat: "/images/socials/game/splat.webp",
    },
    lemon: {
        base: "/images/socials/game/lemonw.webp",
        top: "/images/socials/game/lemonb.webp",
        bottom: "/images/socials/game/lemont.webp",
        splat: "/images/socials/game/splat.webp",
    },
    lime: {
        base: "/images/socials/game/limew.webp",
        top: "/images/socials/game/limeb.webp",
        bottom: "/images/socials/game/limet.webp",
        splat: "/images/socials/game/splat.webp",
    },
    orange: {
        base: "/images/socials/game/orangew.webp",
        top: "/images/socials/game/orangeb.webp",
        bottom: "/images/socials/game/oranget.webp",
        splat: "/images/socials/game/splat.webp",
    },
    pear: {
        base: "/images/socials/game/pearw.webp",
        top: "/images/socials/game/pearb.webp",
        bottom: "/images/socials/game/peart.webp",
        splat: "/images/socials/game/splat.webp",
    },
    gpepper: {
        base: "/images/socials/game/gpepperw.webp",
        top: "/images/socials/game/gpepperb.webp",
        bottom: "/images/socials/game/gpeppert.webp",
        splat: "/images/socials/game/splat.webp",
    },
    ppepper: {
        base: "/images/socials/game/ppepperw.webp",
        top: "/images/socials/game/ppepperb.webp",
        bottom: "/images/socials/game/ppeppert.webp",
        splat: "/images/socials/game/splat.webp",
    },
    rpepper: {
        base: "/images/socials/game/rpepperw.webp",
        top: "/images/socials/game/rpepperb.webp",
        bottom: "/images/socials/game/rpeppert.webp",
        splat: "/images/socials/game/splat.webp",
    },
    pineapple: {
        base: "/images/socials/game/pineapplew.webp",
        top: "/images/socials/game/pineappleb.webp",
        bottom: "/images/socials/game/pineapplet.webp",
        splat: "/images/socials/game/splat.webp",
    },
    plum: {
        base: "/images/socials/game/plumw.webp",
        top: "/images/socials/game/plumb.webp",
        bottom: "/images/socials/game/plumt.webp",
        splat: "/images/socials/game/splat.webp",
    },
    strawberry: {
        base: "/images/socials/game/strawberryw.webp",
        top: "/images/socials/game/strawberryb.webp",
        bottom: "/images/socials/game/strawberryt.webp",
        splat: "/images/socials/game/splat.webp",
    },
    tomato: {
        base: "/images/socials/game/tomatow.webp",
        top: "/images/socials/game/tomatob.webp",
        bottom: "/images/socials/game/tomatot.webp",
        splat: "/images/socials/game/splat.webp",
    },
    watermelon: {
        base: "/images/socials/game/watermelonw.webp",
        top: "/images/socials/game/watermelonb.webp",
        bottom: "/images/socials/game/watermelont.webp",
        splat: "/images/socials/game/splat.webp",
    },
}